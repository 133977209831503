.IndexPage {
  &__image-container1 {
    width: 100%;
    display: block;
    position: relative;
    overflow: auto;
  }

  &__bg1 {
    width: 100%;
  }

  &__logo-big-container {
    position: absolute;
    display: flex;
    width: 40%;
    top: 0;
    bottom: 0;
    left: 15%;
    justify-content: center;
    align-items: center;
  }

  &__logo-big1 {
    width: 100%;
  }
}
